import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Newsrooms`}</h1>
    <p>{`One of Math & Democracy's strategies for strengthening democracy is to empower individuals in shaping a more wholistic understanding of public opinion.`}</p>
    <p>{`Traditional survey technology restricts respondents to a narrow set of dimensions, limiting the breadth and depth of insight they can provide.
As a kind of `}<a parentName="p" {...{
        "href": "/Wikisurvey",
        "title": "Wikisurvey"
      }}>{`Wikisurvey`}</a>{`, `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` allows participants to phrase opinions in their own words, producing a more nuanced and accurate portrait of public opinion.`}</p>
    <p>{`Helping media better represent public opinion translates to a more informed populous, and empowers the public in `}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "/services",
        "title": "services"
      }}>{`services`}</a>{`, many of which are provided pro bono for newsrooms.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      